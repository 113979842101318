import React from 'react';
import './News.css';

import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../state/index';
import { Helmet } from 'react-helmet';

import Button from '../components/Button';
import NewsCard from '../components/NewsCard';
import ImageCard from '../components/ImageCard';

import { newsArray } from '../data.js';

const News = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const newsArticleSelector = useSelector(state => state.newsArticleSelector);
    const showNewsArticle = useSelector(state => state.showNewsArticle);

    const dispatch = useDispatch();
    const { 
        selectArticle, 
        deselectArticle, 
        showArticle, 
        hideArticle 
    } = bindActionCreators(actionCreators, dispatch);


    var newsArrayJSX = [];

    newsArray.slice(-10).forEach(function(obj) {
        newsArrayJSX.push(
            <NewsCard 
                key={obj.id}
                imageChoice={{
                    backgroundImage: `url(${obj.image})`
                }}
                title={obj.title}
                content={obj.description}
                buttonText='Read More'
                formatting='news-page'
                linkTo='news'
                buttonClick={() => {
                    selectArticle(obj.id);
                    showArticle();
                    scrollToTop();
                }}
            />
        );
    });


    return (
        <>

            <Helmet>
                <title>Therapy Reloaded | News</title>
            </Helmet>

            <div className='flexbox-basic'>
                <div className={showNewsArticle ? 'news--container news--container--article' : 'news--container'}>

                    <div className={showNewsArticle ? 'news--card-section--hidden' : 'news--heading'}>
                        <h1 className='news--title'>Our Latest News</h1>
                        <p className='news--subtitle'>Check out our most recent posts</p>
                    </div>

                    <div className={showNewsArticle ? 'news--card-section--hidden' : 'news--card-section'}>

                        {newsArrayJSX}

                    </div>

                    <div className={showNewsArticle ? 'news--article-container' : 'news--card-section--hidden'}>

                        <h1 className='news--article-title'>
                            {newsArray[newsArticleSelector].title}
                        </h1>

                        <ImageCard
                            backgroundChoice='background--article'
                            imageSize='article-card'
                            imageChoice={{
                                backgroundImage: `url(${newsArray[newsArticleSelector].image})`
                            }}
                        />

                        <p className='news--article-paragraph'>
                            {newsArray[newsArticleSelector].paragraph}
                        </p>

                        <div className='news--article-button'>
                            <Button 
                                onClick= {() => { deselectArticle(); hideArticle(); scrollToTop(); }}
                                buttonStyle='btn--primary'
                                buttonSize='btn--large'
                                text='Back to News'
                            />
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default News;
