import NewsCard1 from './assets/News-5.jpg';
import NewsCard2 from './assets/Doctor-img.jpeg';
import NewsCard3 from './assets/Partner-CPNW.png';
import NewsCard4 from './assets/News-Article-4.jpg';
import NewsCard5 from './assets/News-Article-5.jpg';

export const newsArray = [
    {
        'id': 0,
        'image': NewsCard1,
        'title': 'KBBA Awards for Innovation and Health & Wellbeing',
        'description': 'We are double-award winners at the Kingston Borough Business Awards (KBBA)',
        'paragraph' : 'Ubitech were the only double-award winners at the awards ceremony in March 2022 in which we won in two categories: Innovative Use of Technology and Health and Wellbeing.'
    },
    {
        'id': 1,
        'image': NewsCard2,
        'title': 'New Suite of Games being developed for mixed reality',
        'description': 'We are currently working on a new set of games with new control mechanics',
        'paragraph' : 'This involves using a mixed reality headset to control the game via the technology'
    },
    {
        
        'id': 2,
        'image': NewsCard3,
        'title': 'Childrens Physio North West Field Trial Partnership',
        'description': 'Ubitech and a therapy centre in Stockport are working together to trial our games.',
        'paragraph' : 'Kingston University and Ubitech are working together with Children’s Physio North West (CPNW) to trial our current suite of EMG games with individuals who have disabilities.'
    },
    {
        'id': 3,
        'image': NewsCard4,
        'title': 'Brain Injury & Complex Case Management 2024',
        'description': 'Ubitech represented and illustrated our TR platform at BABICIM 2024',
        'paragraph': 'At the Brain Injury & Complex Case Management (BABICIM) 2024 in June 2024 conference where we demonstrated our diverse range of state-of-the-art games such as Balance Board, EMG and Mixed Reality video games for Rehabilitation.'
    },
    {
        'id': 4,
        'image': NewsCard5,
        'title': 'Case Management Society UK 2024',
        'description': 'Ubitech represented and illustrated our TR platform at CSMUK 2024',
        'paragraph': 'At the Case Management Society UK (CSMUK) 2024 conference in  where we demonstrated our diverse range of state-of-the-art games such as Balance Board, EMG and Mixed Reality video games for Rehabilitation.'
    },
];
