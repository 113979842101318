const newsArticleSelectorReducer = (state = 0, action) => {
    
    switch (action.type) {
        case 'SELECT_ARTICLE':
            return action.article;
        case 'DESELECT_ARTICLE':
            return 0;
        default:
            return state;
    }

};

export default newsArticleSelectorReducer;
