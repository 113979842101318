
const showNewsArticleReducer = (state = false, action) => {
    
    switch (action.type) {
        case 'SHOW_NEWS_ARTICLE':
            return action.display;
        case 'HIDE_NEWS_ARTICLE':
            return action.display;
        default:
            return state;
    }

};

export default showNewsArticleReducer;
