const mobileNavMenuReducer = (state = false, action) => {
    
    switch (action.type) {
        case 'OPEN_MOBILE_NAV':
            return action.nav;
        case 'CLOSE_MOBILE_NAV':
            return action.nav;
        default:
            return state;
    }

};

export default mobileNavMenuReducer;
