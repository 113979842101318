import React from 'react'
import './Button.css'
import PropTypes from 'prop-types'


const Button = (props) => {
    return (
        <button
            onClick={props.onClick}
            className={`btn ${props.buttonStyle} ${props.buttonSize}`}
        >
            {props.text}
        </button>
    )
}

Button.defaultProps = {
    buttonStyle: 'btn--outline',
    buttonSize: 'btn--medium',
    text: 'Button',
}

Button.propTypes = {
    buttonStyle: PropTypes.string,
    buttonSize: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
}

export default Button
