
// mobileNaveMenuReducer
export const openNav = () => {
    return (dispatch) => {
        dispatch({
            type: 'OPEN_MOBILE_NAV',
            nav: true
        })
    }
}

export const closeNav = () => {
    return (dispatch) => {
        dispatch({
            type: 'CLOSE_MOBILE_NAV',
            nav: false 
        })
    }
}

// newsArticleSelectorReducer
export const selectArticle = (article) => {
    return (dispatch) => {
        dispatch({
            type: 'SELECT_ARTICLE',
            article: article
        })
    }
}

export const deselectArticle = () => {
    return (dispatch) => {
        dispatch({
            type: 'DESELECT_ARTICLE',
            article: 0
        })
    }
}

// showNewsArticleReducer
export const showArticle = () => {
    return (dispatch) => {
        dispatch({
            type: 'SHOW_NEWS_ARTICLE',
            display: true
        })
    }
}

export const hideArticle = () => {
    return (dispatch) => {
        dispatch({
            type: 'HIDE_NEWS_ARTICLE',
            display: false 
        })
    }
}
