import { combineReducers } from 'redux';

import mobileNavMenuReducer from './mobileNavMenuReducer';
import newsArticleSelectorReducer from './newsArticleSelectorReducer';
import showNewsArticleReducer from './showNewsArticleReducer';

const reducers = combineReducers({
    mobileNavMenu: mobileNavMenuReducer,
    newsArticleSelector: newsArticleSelectorReducer,
    showNewsArticle: showNewsArticleReducer
});

export default reducers;
