import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import './NewsCard.css';

const NewsCard = (props) => {
    return (
        <div className={props.formatting}>
            <div className='news-card--background'>
                <div className='news-card--image' style={ props.imageChoice } />
                    <h1 className='news-card--title'>
                        {props.title}
                    </h1>
                    <p className='news-card--content'>
                        {props.content}
                    </p>
                    <div className='news-card--button'>
                        <div className='news-card--button-center'>
                            <Link to={props.linkTo}>
                                <Button
                                    onClick= {props.buttonClick}
                                    buttonStyle='btn--outline'
                                    buttonSize='btn--small'
                                    text={props.buttonText}  
                                />
                            </Link>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default NewsCard;
