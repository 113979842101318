import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import UbiTech from '../assets/Footer-Ubitech-Logo.svg';


const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer--container'>

                {/* Footer UbiTheraPlay Title */}
                <div className='footer--ubitheraplay'>
                    <span className='footer--ubitheraplay--link'>
                        Therapy Reloaded
                    </span>
                </div>

                {/* Footer Nav Links */}
                <div className='footer--nav-links'>
                    <ul className='footer--nav-links--ul'>
                        <li>
                            <Link to='/games' className='footer--nav-links--link'>
                                Software
                            </Link>
                        </li>
                        <li>
                            <Link to='/news' className='footer--nav-links--link'>
                                News
                            </Link>
                        </li>
                        <li>
                            <a className='footer--nav-links--link' href='https://app.ubitheraplay.com/login' target='_blank' rel='noopener noreferrer'>
                                Portal
                            </a>
                        </li>
                        <li>
                            <Link to='/contact' className='footer--nav-links--link'>
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* Footer Ubitech Logo */}
                <a className='footer--ubitech' target='_blank' rel="noopener noreferrer" href='https://ubitechit.com/'>
                    <img className='footer--ubitech-logo' alt='UbiTech' src={UbiTech} />
                </a>

                {/* Footer Address */}
                {/* <div className='footer--ubitech-address'>
                    <address className='footer--ubitech-address--p'>55-59 Penrhyn Road</address>
                    <address className='footer--ubitech-address--p'>Kingston upon Thames</address>
                    <address className='footer--ubitech-address--p'>Surrey</address>
                    <address className='footer--ubitech-address--p'>KT1 2EE</address>
                </div> */}

                {/* Footer Social Links */}
                <div className='footer--social'>
                    <ul className='footer--social--list'>
                        <li className='footer--social--link'>
                            <a className='footer--social--link--a' target='_blank' rel="noopener noreferrer" href='https://www.linkedin.com/company/therapyreloaded'>
                                <i className="fab fa-linkedin"></i>
                            </a>
                        </li>
                        <li className='footer--social--link'>
                            <a className='footer--social--link--a' target='_blank' rel="noopener noreferrer" href='https://www.youtube.com/@TherapyReloaded'>
                                <i className="fab fa-youtube"></i>
                            </a>
                        </li>
                        <li className='footer--social--link'>
                            <a className='footer--social--link--a' target='_blank' rel="noopener noreferrer" href='https://www.facebook.com/TherapyReloaded/'>
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li className='footer--social--link'>
                            <a className='footer--social--link--a' target='_blank' rel="noopener noreferrer" href='https://www.instagram.com/therapyreloaded/'>
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li className='footer--social--link'>
                            <a className='footer--social--link--a' target='_blank' rel="noopener noreferrer" href='https://twitter.com/TherapyReloaded'>
                                <i className="fab fa-twitter" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Footer Copyright */}
                <div className='footer--copyright'>&copy; {new Date().getFullYear()} Ubitech Ltd</div>

            </div>
        </footer>
    );
}

export default Footer;
