import React from 'react';
import { Helmet } from 'react-helmet';
import './Games.css';
import './Games_v2.css';

import ImageCard from '../components/ImageCard';

import GamesCard1 from '../assets/Games-1.png';
import GamesCard2 from '../assets/Games-2.png';
import GamesCard3 from '../assets/Games-3.png';
import GamesCard4 from '../assets/Games-4.png';
import GamesCard5 from '../assets/Games-5.png';
import GamesCard6 from '../assets/Games-6.png';

const Games = () => {
    return (
        <>
            <Helmet>
                <title>Therapy Reloaded | Software</title>
            </Helmet>

            <div className='software'>
                <div className='software--container'>
                    <h1 className='software--title'>EMG-G and Exer-game Software</h1>
                    <p className='software--subtitle'>Our unique software suite</p>

                    <p className='software--paragraph'>
                        
                        Our software suite includes the Portal, Client, and our range of Electromyography Games (EMG-G) in addition to Exercise Games (exer-games).
                    
                        The Therapy Reloaded suite provides tailor-made video games for the therapeutic support of
                        individuals with neurological conditions affecting motor functions, such as Cerebral Palsy, Hemiplegia, Stroke and Multiple Sclerosis via gamification. The target
                        markets are specialised schools and clinics supporting the therapeutic care of mobility-impaired
                        children and young people.
                    </p>
                </div>
            </div>

            <div className='games'>
                <div className='games--outer-container'>
                    <div className='games--container'>

                        <h1 className='games--title'>Our EMG-Games</h1>
                        <p className='games--subtitle'>We currently offer three specialised muscle-activation games</p>

                        
                        <div className='games--background-v2'>

                        <div className='games--game'>
                            <div className='games--game--card'>
                                <ImageCard
                                    imageSize='games-section-card'
                                    imageChoice={{
                                        backgroundImage: `url(${GamesCard4})`
                                    }}
                                    imageTilt='tilt-left'
                                />
                            </div>
                            <div className='games--game--content-container'>
                                <h1 className='games--game--title'>
                                    Fortune Seeker
                                </h1>
                                <p className='games--game--description'>
                                    Mine your way to fortune by breaking numerous types of rocks and all the while trying to maintain your hunger and stamina within the time limit.
                                    This game not only will test your ability to break rocks, but also to avoid being stung by a wasp! 
                                </p>
                            </div>
                            
                        </div>
                        <div className='games--game--video--container'>
                            <div className='games--game--video--responsive'>
                                <iframe className='games--game--video--iframe'
                                    width="560" 
                                    height="315" 
                                    src="https://www.youtube.com/embed/ds3fvIjShO8"
                                    title="Video player" 
                                    //frameBorder="0" 
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                        <hr />
                        <br />
                        <hr />         

                        <div className='games--game'>
                            <div className='games--game--card'>
                                <ImageCard
                                    imageSize='games-section-card'
                                    imageChoice={{
                                        backgroundImage: `url(${GamesCard5})`
                                    }}
                                    imageTilt='tilt-left'
                                />
                            </div>
                            <div className='games--game--content-container'>
                                <h1 className='games--game--title'>
                                    Tidal Treasures
                                </h1>
                                <p className='games--game--description'>
                                    Accelerate your way to success by overcoming all kinds of obstacles which are in your way to achieve the goal of reaching the end platform. This game also uses muscle activation to enable better control over your upper-limbs.
                                </p>
                            </div>
                            
                        </div>
                        <div className='games--game--video--container'>
                            <div className='games--game--video--responsive'>
                                <iframe className='games--game--video--iframe'
                                    width="560" 
                                    height="315" 
                                    src="https://www.youtube.com/embed/CkKahJGwKSY"
                                    title="Video player" 
                                    //frameBorder="0" 
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                        <hr />
                        <br />
                        <hr />        

                        <div className='games--game'>
                            <div className='games--game--card'>
                                <ImageCard
                                    imageSize='games-section-card'
                                    imageChoice={{
                                        backgroundImage: `url(${GamesCard6})`
                                    }}
                                    imageTilt='tilt-left'
                                />
                            </div>
                            <div className='games--game--content-container'>
                                <h1 className='games--game--title'>
                                    Galaxy Fighter: Flex
                                </h1>
                                <p className='games--game--description'>
                                    Travel through the galaxy while defeating enemies and alien bosses in orbit. Galaxy Fighter: Flex is based upon our unique and specialised control mechanics, while improving your muscle control.
                                </p>
                            </div>
                            
                        </div>
                        <div className='games--game--video--container'>
                            <div className='games--game--video--responsive'>
                                <iframe className='games--game--video--iframe'
                                    width="560" 
                                    height="315" 
                                    src="https://www.youtube.com/embed/g6fEzMEPi9s"
                                    title="Video player" 
                                    //frameBorder="0" 
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                        <hr /> 

                        </div>

                        
                        <h1 className='games--title'>Our Exer-games</h1>
                        <p className='games--subtitle'>We currently offer three specialized exer-games playable via Balance Board</p>
                        
                        <div className='games--background-v2'>

                            <div className='games--game'>
                                <div className='games--game--card'>
                                    <ImageCard
                                        imageSize='games-section-card'
                                        imageChoice={{
                                            backgroundImage: `url(${GamesCard1})`
                                        }}
                                        imageTilt='tilt-left'
                                    />
                                </div>
                                <div className='games--game--content-container'>
                                    <h1 className='games--game--title'>
                                        Runner eXer
                                    </h1>
                                    <p className='games--game--description'>
                                        Run from one side of the bridge to the other, while collecting gems and avoiding hurdles. This endless runner game ranges across a variety of difficulty levels and is great for improving and managing your static and dynamic balance abilities - a mixture of immersive fun and effective balance training.
                                    </p>
                                </div>
                                
                            </div>
                            <div className='games--game--video--container'>
                                <div className='games--game--video--responsive'>
                                    <iframe className='games--game--video--iframe'
                                        width="560" 
                                        height="315" 
                                        src="https://www.youtube.com/embed/LkgXkbfb_bU" 
                                        title="Video player" 
                                        //frameBorder="0" 
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                            <hr /> 
                            <br />
                            <hr />        

                            <div className='games--game'>
                                <div className='games--game--card'>
                                    <ImageCard
                                        imageSize='games-section-card'
                                        imageChoice={{
                                            backgroundImage: `url(${GamesCard2})`
                                        }}
                                        imageTilt='tilt-left'
                                    />
                                </div>
                                <div className='games--game--content-container'>
                                    <h1 className='games--game--title'>
                                        Exermaze
                                    </h1>
                                    <p className='games--game--description'>
                                        This maze-based game consists of numerous interactive, engaging, and fun mazes which range across various difficulties. The objective is to travel around the maze collecting all the tokens before exiting. This game develops four directional lean movements and pressure control.
                                    </p>
                                </div>
                                
                            </div>
                            <div className='games--game--video--container'>
                                <div className='games--game--video--responsive'>
                                    <iframe className='games--game--video--iframe'
                                        width="560" 
                                        height="315" 
                                        src="https://www.youtube.com/embed/H3yi-HU8K2A" 
                                        title="Video player" 
                                        //frameBorder="0" 
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                            <hr />
                            <br />
                            <hr />         

                            <div className='games--game'>
                                <div className='games--game--card'>
                                    <ImageCard
                                        imageSize='games-section-card'
                                        imageChoice={{
                                            backgroundImage: `url(${GamesCard3})`
                                        }}
                                        imageTilt='tilt-left'
                                    />
                                </div>
                                <div className='games--game--content-container'>
                                    <h1 className='games--game--title'>
                                        Spark in Space
                                    </h1>
                                    <p className='games--game--description'>
                                        Travel through orbit while getting fun collectibles and avoiding obstacles, making your way to defeating bosses from multiple planets. Spark in Space is based on a two-directional control method using the balance board to navigate through space, improving both dynamic and static balance abilities.
                                    </p>
                                </div>
                                
                            </div>
                            <div className='games--game--video--container'>
                                <div className='games--game--video--responsive'>
                                    <iframe className='games--game--video--iframe'
                                        width="560" 
                                        height="315" 
                                        src="https://www.youtube.com/embed/ACGCukDJ63M"
                                        title="Video player" 
                                        //frameBorder="0" 
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                            <hr />
                            <br />
                            <hr /> 
                            </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Games;
